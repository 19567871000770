import api from "./api";
export default {
  add(data) {
    return api.execute(`post`, `/moderator/provider`, data);
  },
  update(data) {
    return api.execute(`put`, `/moderator/provider`, data);
  },
  // get(payload) {
  //   return api.execute("get", `/moderator/provider`);
  // },

  get(payload = {}) {
    const queryString = new URLSearchParams(payload).toString();
    return api.execute("get", `/moderator/provider?${queryString}`);
  },

  remove(data) {
    return api.execute(`delete`, `/moderator/provider`, data);
  },
};
