<template>
  <div>
    <Breadcrumbs main="" title="Services" />
    <div>
      <b-row class="p-3 p-md-2 d-flex align-items-center">
        <b-col class="col-md-3 col-12">
          <b-form-group label="Provider">
            <multiselect
              :options="providerlist"
              label="name"
              v-model="selectedProvider"
              placeholder="Select service provider"
              :multiple="false"
              :close-on-select="true"
              track-by="_id"
            ></multiselect>
          </b-form-group>
        </b-col>
        <b-col class="col-md-2 col-12">
          <b-form-group label="Category">
            <multiselect
              :options="categoryArray"
              label="name"
              v-model="selectedCategory"
              placeholder="Select Category"
              :multiple="false"
              :close-on-select="true"
              track-by="id"
            ></multiselect>
          </b-form-group>
        </b-col>
        <b-col class="col-md-2 col-12">
          <b-form-group label="Type">
            <multiselect
              :options="adTypeArray"
              label="name"
              v-model="selectedAdType"
              placeholder="Select Ad Type"
              :multiple="false"
              :close-on-select="true"
              track-by="id"
            ></multiselect>
          </b-form-group>
        </b-col>
        <b-col class="date-range-custom1">
          <b-form-group label="Date Range">
            <date-range-picker
              style="display: inline"
              ref="picker"
              opens="center"
              :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="false"
              :showWeekNumbers="false"
              :showDropdowns="true"
              :autoApply="false"
              v-model="dateRange"
              @update="ChangedDate()"
              :ranges="customDateRanges"
              :linkedCalendars="false"
              :max-date="new Date()"
            >
            </date-range-picker>
          </b-form-group>
        </b-col>
        <b-col class="d-flex">
          <button
            @click="filterData"
            class="btn custom-btn"
            style="margin-top: 1vh"
          >
            Filter
          </button>
          <button
            @click="resetFilters()"
            class="ml-2 btn custom-btn"
            style="margin-top: 1vh"
          >
            Reset
          </button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card style="overflow: auto" class="custom-modal-card-2">
            <b-row>
              <span class="custome-title col-md-12 mb-3">Services</span>
            </b-row>
            <div class="col-md-12" v-if="isBusy">
              <h6 class="sub-title mb-0 text-center">Loading...</h6>
              <div class="loader-box">
                <div class="loader-30"></div>
              </div>
            </div>
            <div v-else>
              <div class="list-container">
                <ul class="list-unstyled row">
                  <li
                    class="col-md-6 col-12 mb-2"
                    v-for="(item, index) in serData"
                    :key="index"
                  >
                    <span
                      @click.prevent="editTable(item)"
                      class="custom-font-24 cust-wrap-text"
                      style="cursor: pointer"
                    >
                      <span title="Provider">{{
                        sentenceCase(item.provider_name)
                      }}</span>
                      -
                      <span title="Service">{{
                        sentenceCase(item.title)
                      }}</span>
                      <span class="ml-2">
                        [{{
                          currentMonth[item._id]
                            ? currentMonth[item._id]["total"]
                            : 0
                        }}
                        |
                        {{
                          lastMonth[item._id]
                            ? lastMonth[item._id]["total"]
                            : 0
                        }}]
                      </span>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
// Tables
import SocialTrafficTable from "./Dashboard/SocialTrafficTable";
import PageVisitsTable from "./Dashboard/PageVisitsTable";

// Services
import serviceApi from "../../services/ServiceInfo";
import ContentView from "./Dashboard/Content.vue";
import ServiceTable from "../views/Dashboard/ServicesTable.vue";
import ServiceList from "../views/ServicesList.vue";
import config from "../../services/globalLit";
import ServiceProviderServices from "../../services/ServiceProviderServices";
import Multiselect from "vue-multiselect";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
export default {
  components: {
    ServiceList,
    PageVisitsTable,
    SocialTrafficTable,
    ContentView,
    ServiceTable,
    Multiselect,
    DateRangePicker,
  },
  data() {
    const today = new Date();
    const startOfToday = new Date(today.setHours(0, 0, 0, 0));
    const endOfToday = new Date();

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const startOfYesterday = new Date(yesterday.setHours(0, 0, 0, 0));
    const endOfYesterday = new Date(yesterday.setHours(23, 59, 59, 999));

    return {
      dateRange: {
        // startDate: startOfToday,
        startDate: new Date().setMonth(new Date().getMonth() - 2), 
        endDate: endOfToday,
      },
      customDateRanges: {
        'Today': [startOfToday, endOfToday],
        'Yesterday': [startOfYesterday, endOfYesterday],
        'Last 7 Days': [
          new Date(today.getTime() - 6 * 24 * 60 * 60 * 1000),
          endOfToday,
        ],
        'Last 30 Days': [
          new Date(today.getTime() - 29 * 24 * 60 * 60 * 1000),
          endOfToday,
        ],
        'This Week': [
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay(), 0, 0, 0),
          endOfToday,
        ],
        'Last Week': [
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 7, 0, 0, 0),
          new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 1, 23, 59, 59),
        ],
        'Last 3 Weeks': [
          new Date(today.getTime() - 21 * 24 * 60 * 60 * 1000),
          endOfToday,
        ],
        'This Month': [
          new Date(today.getFullYear(), today.getMonth(), 1),
          endOfToday,
        ],
        'Last Month': [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59),
        ],
        'Last 3 Months': [
          new Date(today.getFullYear(), today.getMonth() - 3, 1),
          endOfToday,
        ],
      },
      userdata: [],
      isBusy: false,
      perPage: 10,
      rows: 100,
      currentPage: 1,
      serData: [],
      currentMonth: {},
      lastMonth: {},
      serFields: [
        {
          key: "_id",
          label: "Services",
        },
        {
          key: "total",
          label: "[currentMonth]|[lastMonth]",
        },
      ],
      userActivateStatus: false,
      user: "",
      user_status: false,
      fields: [
        {
          key: "link",
          label: "Links",
          sortable: true,
          editable: true,
          tbClass: "bTableThStyle",
        },
        {
          key: "copy",
          label: "action",
          tbClass: "acClass",
        },
      ],
      selectedProvider: null,
      selectedCategory: null,
      selectedAdType: null,
      filter: {},
      providerlist: [],
      adTypeArray: config.adTypeArray,
      categoryArray: config.categorylist,
    };
  },
  methods: {
    async filterData() {
      this.filter.spid = this.selectedProvider
        ? this.selectedProvider._id
        : null;
      this.filter.category = this.selectedCategory
        ? this.selectedCategory.id
        : null;
      this.filter.adtype = this.selectedAdType ? this.selectedAdType.id : null;
      this.filter.sdate = this.dateRange.startDate;
      this.filter.edate = this.dateRange.endDate;
      this.init("filter");

      console.log("this.dateRange.startDate", this.dateRange.startDate);
      console.log("this.dateRange.endDate", this.dateRange.endDate);
    },

    async resetFilters() {
    // Reset all filter fields
    this.selectedProvider = null;
    this.selectedCategory = null;
    this.selectedAdType = null;

    // Reset date range to today
    this.dateRange = {
      startDate: new Date().setMonth(new Date().getMonth() - 2),
      endDate: new Date(), // Today end
    };

    // Ensure DateRangePicker gets reset
    this.$nextTick(() => {
      if (this.$refs.picker) {
        this.$refs.picker.setStartDate(this.dateRange.startDate);
        this.$refs.picker.setEndDate(this.dateRange.endDate);
      }
    });

    this.filter = {}; // Reset filter object
    await this.init();
  },
    sentenceCase(str) {
      if (typeof str !== "string") return str;
      return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
    },
    editTable(item) {
      item.sdate =
        this.sdate || new Date(this.dateRange.startDate).toISOString();
      item.edate = this.edate || new Date(this.dateRange.endDate).toISOString();

      const url = this.$router.resolve({
        name: "DetailedPage",
        query: {
          id: item._id,
          sdate: item.sdate,
          edate: item.edate,
        },
      }).href;

      window.open(url, "_blank");

      this.$root.$emit("showtrxforlist", item);
      this.$bvModal.show("openSerList");
      return;
    },
    decodeString(str) {
      var div = document.createElement("textarea");
      div.innerHTML = str;
      var decoded = div.firstChild.nodeValue;

      return decoded;
    },
    maxWidth() {
      return "text-truncate";
    },
    showModal(item) {
      this.addetail = item;
      this.videoUrl = `${process.env.VUE_APP_BACKHOST}:${process.env.VUE_APP_BACKPORT}/videos/sr_${item["_id"]}.mp4`;
    },
    async getChains(id) {
      let data = await dashbordService.chains(id);
      this.linkschain = data.data.map((e) => {
        return { link: e };
      });
    },
    copyText(link) {
      navigator.clipboard.writeText(link);
      this.$notify({
        type: "info",
        message: "Copied to clipboard",
      });
    },
    async init(txt) {
      this.isBusy = true;

      


      // var payload = {};
      var payload = txt ? this.filter : {};
      if (txt) {
        payload = this.filter;
      }

      try {
      } catch (e) {
        console.error("Error in getting monthly services");
        console.error(e.message);
      }
      let response = await serviceApi.getMonthlyServices(payload);
      if (response.result) {
        this.currentMonth = response.data.cMonth;
        this.lastMonth = response.data.lMonth;
        // this.serData = response.data.servicesList;
        let serData = response.data.servicesList;
        this.serData = [];
        serData.map((e) => {
          if (this.currentMonth[e._id] || this.lastMonth[e._id]) {
            this.serData.push(e);
          }
        });
      }
      this.isBusy = false;
    },
    async getSplist() {
      let spData = await ServiceProviderServices.get();
      if (spData.result) {
        this.providerlist = spData.data;
      }
    },
    ChangedDate() {
     
    },
    changeDateFormate(d) {
      return moment.utc(d).format("D/M/YYYY");
    },
  },
  mounted() {
    this.getSplist();
    this.init();
    this.userdata = JSON.parse(window.localStorage.getItem("userinfo"));
    this.userActivateStatus = this.userdata.isactive;
  },
};
</script>
<style>
.even {
  background-color: #dae5e9;
}
.odd {
  background-color: #d6d4d4;
}
</style>
<style scoped>
.table .acClass {
  background-color: red;
}
.table .bTableThStyle {
  background-color: blue !important;
  /* max-width: '100px' !important; */
  text-overflow: ellipsis !important;
}
.table > tbody > tr > td {
  max-width: 350px !important;
  /* text-justify: auto; */
  width: 1000px;
  /* white-space: nowrap; */
  overflow: auto;
  text-overflow: ellipsis;
}
.modal-dialog-1 {
  position: fixed;
  margin: auto;

  height: 100%;
  right: 0px;
}
.modal-content-1 {
  height: 100%;
}


.list-container {
  position: relative;
}

.cust-wrap-text {
  display: block;
  max-width: 28em;
  order: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.cust-wrap-text:hover {
  color: #274aa6;
  max-width: auto;
  text-overflow: clip;
  white-space: normal;
}

@media (min-width: 768px) {
  .list-container::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 48%;
    border-left: 2px dashed rgb(204, 204, 204);
    transform: translateX(-50%);
  }
}
.showBreadcrumbs {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .custom-font-24 {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 991px) {
  .showBreadcrumbs {
    display: block !important;
  }
}
</style>
